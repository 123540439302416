<template>
  <v-autocomplete
    @update:search-input="isTyping = true" 
    @input="chosenPerson"
    @click:clear="clearSearch"
    v-model="model"
    :search-input.sync="searchQuery" 
    label="Search Applicant"
    :items="searchResult"
    :item-text="getApplicantInfo"
    item-value="OID"
    :hide-no-data="hideNoData"
    :no-data-text="noDataText"
    :loading="loading"
    prepend-icon="mdi-account"
    :menu-props="{ offsetY: true }"
    clearable
    return-object
    hint="At least 5 characters"
    :color="color"
  ></v-autocomplete>
</template>

<script>
// import _ from 'lodash'
import debounce from 'lodash/debounce'
import axios from 'axios'

export default {
  name: 'search-buyer',
  props: {
    color: {
      type: String,
      default: 'blue'
    }
  },
  data() {
    return {
      model: null,
      searchQuery: null,
      isTyping: false,
      searchResult: [],
      loading: false,
      hideNoData: true,
      noDataText: '',
    }
  },
   watch: {
    searchQuery: debounce(function() {
      this.isTyping = false;
    }, 1000),
    isTyping: function(value) {
      if (!value && this.searchQuery && this.searchQuery.length > 5) {
        this.searchPerson(this.searchQuery)
        this.loading = true
      }
    }
  },
  methods: {
    clearSearch() {
      this.$emit('clearSearch')
    },
    getApplicantInfo(obj) {
      return `${obj.Name} - ${obj.Email}`
    },
    chosenPerson() {
      const selectedPersons = this.model
      this.$emit('selectedApplicant', selectedPersons)
      this.model = null
    },
    searchPerson(query) {
      axios({
        method: 'GET',
        url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/search/applicant`,
        params: {
          name: query
        },
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( ({data}) => {
        if(data.Count > 0) {
          this.searchResult = data.Data
          this.loading = false
          console.log('Applicant search OK')
        }
        else {
          this.hideNoData = false
          this.loading = false
          this.noDataText = "No applicant found with this name"
        }
      })
      .catch( (err) => {
        this.hideNoData = false
        this.loading = false
        this.noDataText = "An error has occured in the system. Try again."
        console.log('Error with search cloud!')
        console.log(err)
      })
    }
  }
}
</script>
