<template>
  <div>
    <v-container class="pa-0 pa-sm-5">
      <v-carousel 
      class="mt-0"
      vertical-delimiters 
      vertical 
      v-model="model"
      :continuous="continuous"
      delimiter-icon="mdi-circle-medium"
      height="80vh"
    >
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="orange"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <!-- <div class="text-h4"> -->
                <!-- Online Application
                <p class="text-body-2">In 10 quick steps</p> -->
                <v-row align="center" class="">
                  <v-col cols="12" sm="6" class="d-flex flex-column justify-start">
                    <div class="text-h4">
                      Add Application
                      <p class="text-body-2">In 10 quick steps</p>
                    </div>
                    <v-btn :autofocus="model === 0"  outlined @click="model++" width="150" :x-large="$vuetify.breakpoint.smAndUp" color="white">
                      <span class="white--text">Start</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="d-none d-md-flex">
                    <v-icon id="intro-icon" size="300" >mdi-card-account-details-outline</v-icon>
                  </v-col>
                </v-row>
              <!-- </div> -->
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="secondary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">1. Tenancy
                <v-row class="mt-3">
                  <v-col cols="12" sm="6">
                    <SearchLettingProperties 
                      color="white"
                      v-if="!selectedTenancy"
                      @selectedLettingProperty="setTenancy"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col cols="2">
                        <v-icon @click="selectedTenancy = '' " class="mr-3" v-if="selectedTenancy">mdi-close</v-icon>
                      </v-col>
                      <v-col v-if="selectedTenancy">
                        <div class="text-subtitle-1 text-sm-h6 d-flex flex-wrap align-center">
                          <span>{{ selectedTenancy.Property.AddressNumber }} {{ selectedTenancy.Property.Address1 }}</span>
                        </div>
                        <span class="text-h5 text-sm-h3">{{ selectedTenancy.Property.Postcode }}</span>
                      </v-col>
                      <v-col v-if="noTenancy">
                        <p class="red--text">The selected property has no registered tenancy.</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-btn @click="model++" color="white" text>Next</v-btn>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="primary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">2. Applicant
                <v-row class="mt-3">
                  <v-col cols="12" sm="6">
                    <SearchTenancyApplicant 
                      color="white"
                      v-if="!selectedPerson.id"
                      @selectedApplicant="setPerson"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col cols="2">
                        <v-icon @click="selectedPerson = '' " class="mr-3" v-if="selectedPerson.id">mdi-close</v-icon>
                      </v-col>
                      <v-col v-if="selectedPerson" class="text-h6 text-sm-h3">
                        <span class="text-subtitle-1 text-sm-h5"  v-if="selectedPerson.title">{{ selectedPerson.title}}</span>
                        {{ selectedPerson.forename }} 
                        {{ selectedPerson.surname }}
                        <p v-if="selectedPerson.EmailAddress" class="mt-3 mb-0 text-body-1">{{ }}</p>
                        <p v-if="selectedPerson.MobilePhone" class="mt-0 text-body-1">{{ selectedPerson.MobilePhone }}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="space-between" align="center">
                  <v-btn text v-if="!selectedPerson.id" @click="model++" color="white">
                    <span class="custom-transform-class text-none">Create a new applicant</span>
                    <v-icon right small>mdi-account-plus</v-icon>
                  </v-btn>
                  <v-btn @click="model++" color="white" text>Next</v-btn>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="primary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">3. Applicant details
                <v-row class="mt-3">
                  <v-col class="pt-3" cols="12">
                    <v-row>
                      <v-col class="my-n3" cols="4" sm="2">
                        <v-text-field
                          :autofocus="model === 3"
                          outlined dense
                          label="Title"
                          v-model="selectedPerson.title"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col class="d-none d-sm-flex" cols="0" sm="8"></v-col> -->
                      <v-col class="my-n3" cols="12" sm="5">
                        <v-text-field
                          outlined dense
                          label="Forename"
                          v-model="selectedPerson.forename"
                        ></v-text-field>
                      </v-col>
                      <v-col class="my-n3" cols="12" sm="5">
                        <v-text-field
                          outlined dense
                          label="Surname"
                          v-model="selectedPerson.surname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="primary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">4. Contact details
                <v-row class="mt-3">
                  <v-col class="pt-3" cols="12">
                    <v-row>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-text-field
                          :autofocus="model === 4"
                          outlined dense
                          label="Email"
                          v-model="selectedPerson.email"
                          type="email"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="0" sm="6"></v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-text-field
                          outlined dense
                          label="Mobile phone"
                          v-model="selectedPerson.mobile"
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item> 
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="primary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">5. Identification
                <v-row class="mt-3">
                  <v-col class="pt-3" cols="12">
                    <v-row>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-text-field
                          v-model="dateOfBirth"
                          :autofocus="model === 5"
                          outlined dense
                          type="date"
                          label="Date of birth"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="0" sm="6"></v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-select
                          :items="countryNames.countryNames"
                          label="Nationality"
                          v-model="nationality"
                          outlined dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>  
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="primary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">6. Current status
                <v-row class="mt-3">
                  <v-col class="pt-3" cols="12">
                    <v-row>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-select
                          :autofocus="model === 6"
                          outlined dense
                          v-model="selectedLivingStatus"
                          :items="livingStatus"
                          label="Current living status"
                        ></v-select>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="0" sm="6"></v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-select
                          v-model="selectedTenantType"
                          outlined dense
                          :items="tenantType"
                          label="Employment status"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="primary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">7. Current address
                <v-row class="mt-3">
                  <v-col class="pt-3" cols="12">
                    <v-row>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          :autofocus="model === 7"
                          outlined dense
                          label="Address line 1"
                          v-model="currentAddress.addressLine1"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="0" sm="6"></v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          outlined dense
                          label="Address line 2"
                          v-model="currentAddress.addressLine2"
                        ></v-text-field>
                      </v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          outlined dense
                          label="Town/City"
                          v-model="currentAddress.townCity"
                        ></v-text-field>
                      </v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          outlined dense
                          label="Postcode"
                          v-model="currentAddress.postcode"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="green"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">8. Current landlord
                <v-row class="mt-3">
                  <v-col class="pt-3" cols="12">
                    <v-row>
                      <v-col class="my-n3" cols="4" sm="2">
                        <v-text-field
                          :autofocus="model === 8"
                          outlined dense
                          label="Title"
                          v-model="currentLandlord.title"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="8"></v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          :autofocus="model === 8"
                          outlined dense
                          label="Forename"
                          v-model="currentLandlord.forename"
                        ></v-text-field>
                      </v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          outlined dense
                          label="Surname"
                          v-model="currentLandlord.surname"
                        ></v-text-field>
                      </v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-text-field
                            outlined dense
                            label="Email"
                          v-model="currentLandlord.email"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="6"></v-col>
                      <v-col class="my-n3" cols="12" sm="4">
                        <v-text-field
                            outlined dense
                            label="Mobile number"
                          v-model="currentLandlord.mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>   
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="orange darken-4"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">9. Current employer
                <v-row class="mt-3">
                  <v-col class="pt-3" cols="12">
                    <v-row>
                    <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          :autofocus="model === 9"
                          outlined dense
                          label="Company name"
                          v-model="currentEmployer.companyName"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <p mt-n5 class="text-body-1 font-weight-bold">Contact person</p>

                    <v-row>
                      <v-col class="my-n3" cols="4" sm="2">
                        <v-text-field
                          outlined dense
                          label="Title"
                          v-model="currentEmployer.contactPerson.title"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="8"></v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          outlined dense
                          label="Forename"
                          v-model="currentEmployer.contactPerson.forename"
                        ></v-text-field>
                      </v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                       <v-text-field
                          outlined dense
                          label="Surname"
                          v-model="currentEmployer.contactPerson.surname"
                        ></v-text-field>
                      </v-col>
                      <v-col class="my-n3" cols="12" sm="6">
                        <v-text-field
                            outlined dense
                            label="Email"
                            v-model="currentEmployer.contactPerson.email"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-none d-sm-flex" cols="6"></v-col>
                      <v-col class="my-n3" cols="12" sm="4">
                        <v-text-field
                            outlined dense
                            label="Mobile number"
                            v-model="currentEmployer.contactPerson.mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn @click="model++" color="white" text>Next</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>                                
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="orange"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
          <v-col cols="9" offset="1" sm="9" offset-sm="1">
            <div class="text-h4">10. General notes
              <v-row class="mt-3">
                <v-col cols="12" sm="10">
                  <v-textarea
                    ref="internalNotesText"
                    clearable
                    outlined
                    :autofocus="model === 10"
                    name="input-7-4"
                    label="Enter notes"
                    v-model="generalNotes"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-btn @click="model++" color="white" text>Next</v-btn>
              </v-row>
            </div>
          </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="blue"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
          <v-col cols="9" offset="1" sm="9" offset-sm="1">
            <div class="text-h4">
              Upload documents
              <p class="text-body-1">Max. 5mb</p>
              <v-row class="mt-3">
                <v-col cols="12" sm="8">
                  <v-file-input 
                    :autofocus="model === 10"
                    @change="uploadDocuments" 
                    small-chips 
                    outlined 
                    multiple
                    show-size counter 
                    label="Select multiple files for upload"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row v-if="attachedDocuments.length">
                <div>
                  <v-chip
                    v-for="(doc, index) in attachedDocuments"
                    :key="doc.DocumentName"
                    class="ma-2"
                    close
                    color="white"
                    text-color="blue"
                    @click:close="removeDocument(index)"
                  >
                    <v-avatar left>
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    {{ doc.DocumentName }}
                  </v-chip>
                </div>
              </v-row>
              <p class="text-body-2" v-if="attachedDocuments.length">{{ attachmentsTotalSize.toFixed(1) }}Mb</p>
              <v-row justify="end">
                <v-btn @click="model++" color="white" text>Next</v-btn>
              </v-row>
            </div>
          </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        v-touch="{
          up: () => swipe('Up'),
          down: () => swipe('Down')
        }"
      >
        <v-sheet
          color="primary"
          height="100%"
          tile
        >
          <v-row
            @wheel="onWheel"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4">Summary
                <v-row class="mt-3">
                  <v-col cols="12" sm="10">
                      <!-- Alerts -->
                    <div v-if="!selectedTenancy" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 1">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Tenancy details are required.</span>
                      </v-btn>
                    </div>
                    <div v-if="!selectedPerson.forename || !selectedPerson.surname" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 2">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Applicant`s full name is required.</span>
                      </v-btn>
                    </div>
                    <div v-if="!selectedPerson.email || !selectedPerson.mobile" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 4">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Applicant`s contact details are required.</span>
                      </v-btn>
                    </div>
                    <div v-if="attachmentsTotalSize > 5" class="my-2 d-flex align-center">
                      <v-btn text @click="model = 12">
                        <v-icon color="white" left>mdi-alert</v-icon>
                        <span class="text-body-1 text-sm-h6 font-weight-bold white--text">Attached documents size exceeds 5mb.</span>
                      </v-btn>
                    </div>
                    <div v-if="allFieldsOK" class="text-body-1">
                      Submit tenancy application on behalf of <span class="font-weight-bold">{{ selectedPerson.forename }} {{ selectedPerson.surname }}</span> 
                      fot <span class="font-weight-bold">{{selectedTenancy.Property.AddressNumber}} {{ selectedTenancy.Property.Address1}}</span>.                       
                    </div>
                    <v-btn :loading="loading" class="mt-5" v-if="allFieldsOK"  @click="makeOffer" color="success">Submit</v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
       <v-carousel-item
          v-if="applicationSuccessful"
        >
          <v-sheet
            color="green"
            height="100%"
            tile
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4 text-sm-h2">
                Online application submitted successfully.
                <v-row justify="start" class="mt-3">
                  <v-col cols="12">
                    <v-btn @click="startNewApplication" color="white" outlined>
                      <span>New application</span>
                      <v-icon small right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item
          v-if="applicationUnsuccessful"
        >
          <v-sheet
            color="red"
            height="100%"
            tile
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <v-col cols="9" offset="1" sm="9" offset-sm="1">
              <div class="text-h4 text-sm-h2">
                Error submitting offer.
                <v-row justify="start" class=" mt-3">
                  <v-col class=" px-0" cols="12">
                    <p class="text-body-1 pl-3">{{ errorMsg }}. If the error persists, please contact support.</p>
                    <v-btn @click="startNewApplication" color="white" outlined>
                      <span>New application</span>
                      <v-icon small right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>

      <div id="buttons-wrapper">
        <v-row v-if="model !== 13" class="mt-3 mb-12" justify="end">
          <v-col 
            class="d-flex align-center justify-space-around" 
            cols="8" offset="2" 
            sm="4" offset-sm="8"
          >
            <v-btn small @click="model--" color="white" text>
              <v-icon >mdi-arrow-up</v-icon>
            </v-btn>
            <span class="white--text">Page {{ model + 1 }} of 13</span>
            <v-btn v-if="model !== 12" small @click="model++" color="white" text>
              <v-icon >mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>

    </v-carousel>

    </v-container>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import SearchLettingProperties from '@/components/Searches/SearchLettingProperties.vue';
import SearchTenancyApplicant from '@/components/Searches/SearchTenancyApplicant.vue';
import countryNames from '@/components/CountryNames.js'

export default {
  name: 'letting-application',
  components: {
    SearchLettingProperties,
    SearchTenancyApplicant,
  },
  data () {
    return {
      model: 0,
      loading: false, 
      applicationSuccessful: false,
      applicationUnsuccessful: false,
      errorMsg: '',
      countryNames,
      //submission model
      selectedTenancy: '',
      selectedPerson: {
        id: '',
        title: '',
        forename: '',
        surname: '',
        mobile: '',
        email: ''
      },
      currentAddress: {
        addressLine1: '',
        addressLine2: '',
        townCity: '',
        postcode: ''
      },
      currentLandlord: {
        title: '',
        forename: '',
        surname: '',
        email: '',
        mobile: ''
      },
      currentEmployer: {
        companyName: '',
        contactPerson: {
          title: '',
          forename: '',
          surname: '',
          email: '',
          mobile: '',
        }
      },
      dateOfBirth: '',
      nationality: '',
      selectedTenantType: '',
      selectedLivingStatus: '',
      generalNotes: '',
      attachedDocuments: [],
      //other
      continuous: false,
      livingStatus: [
        'Own property',
        'Rents',
        'Living with relatives',
        'Lives with friends'
      ],
      tenantType: [
        'Employed',
        'Self employed',
        'Unemployed',
        'Student',
        'Own means',
        'Retired',  
        'Company',
        'Council'
      ],
      noTenancy: false
    }
  },
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user !== ''){
    if(this.$store.state.OffersApplications.selectedApplicant) {
      this.selectedPerson.id = this.$store.state.OffersApplications.selectedApplicant.OID
      this.selectedPerson.title = this.$store.state.OffersApplications.selectedApplicant.Title
      this.selectedPerson.forename = this.$store.state.OffersApplications.selectedApplicant.Forename
      this.selectedPerson.surname = this.$store.state.OffersApplications.selectedApplicant.Surname
      this.selectedPerson.email = this.$store.state.OffersApplications.selectedApplicant.EmailAddress
    }
  }
  else{
    this.$router.push('/');
  }
  },
  beforeDestroy() {
    this.$store.commit('OffersApplications/CLEAR_SELECTED_APPLICANT')
  },
  computed: {
    allFieldsOK() {
      if(this.selectedTenancy && this.selectedPerson.forename && this.selectedPerson.surname && this.selectedPerson.email && this.selectedPerson.mobile && this.attachmentsTotalSize < 5) { return true }
      return false
    },
    attachmentsTotalSize() {
      if(this.attachedDocuments.length) {
        const totalSize = this.attachedDocuments.map(item => item.Size).reduce( (acc, current) => {
          return acc + current
        })
        const sizeInMb = (totalSize / 1000000)
        return sizeInMb
      }
      return 0
    }
  },
  methods: {
    startNewApplication() { // resetting all values
      this.model = 0
      this.applicationsUnsuccessful = false
      this.applicationSuccessful = false
      this.errorMsg = ''
      this.selectedTenancy = '',
      this.selectedPerson = {
        id: '',
        title: '',
        forename: '',
        surname: '',
        mobile: '',
        email: ''
      }
      this.currentAddress = {
        addressLine1: '',
        addressLine2: '',
        townCity: '',
        postcode: ''
      }
      this.currentLandlord = {
        title: '',
        forename: '',
        surname: '',
        email: '',
        mobile: ''
      }
      this.currentEmployer = {
        companyName: '',
        contactPerson: {
          title: '',
          forename: '',
          surname: '',
          email: '',
          mobile: '',
        }
      }
      this.dateOfBirth = ''
      this.nationality = ''
      this.selectedTenantType = ''
      this.selectedLivingStatus = ''
      this.generalNotes = ''
      this.attachedDocuments = []
    },
    makeOffer() {
      this.loading = true
      const payload = {
        tenancyID: this.selectedTenancy.OID,
        Applicant: {
          TenancyRef: this.selectedTenancy.GlobalReference,
          Title: this.selectedPerson.title,
          Forename: this.selectedPerson.forename,
          Surname: this.selectedPerson.surname,
          DOB: this.dateOfBirth,
          Nationality: this.nationality,
          MobilePhone: this.selectedPerson.mobile,
          EmailAddress: this.selectedPerson.email,
          TenantType: this.selectedTenantType,
          GeneralNotes: this.generalNotes,
          Reference: [
          // Current address 
            {
              Type: "PreTenancyAddress",
              Address1: this.currentAddress.addressLine1,
              Address2: this.currentAddress.addressLine2,
              Postcode: this.currentAddress.postcode,
              // Country: '',
            },
            //Current landloard
            {
            Type: 'PreTenancyLandlord',
            Title: this.currentLandlord.title,
            Forename: this.currentLandlord.forename,
            Surname: this.currentLandlord.surname,
            Email: this.currentLandlord.email,
            MobilePhone: this.currentLandlord.mobile 
            },
            //Current employer
            {
            Type: 'Employer',
            OrganisationName: this.currentEmployer.companyName,
            Title: this.currentEmployer.contactPerson.title,
            Forename: this.currentEmployer.contactPerson.forename,
            Surname: this.currentEmployer.contactPerson.surname,
            Email: this.currentEmployer.contactPerson.email,
            MobilePhone: this.currentEmployer.contactPerson.mobile
            }
          ],
          Documents: this.attachedDocuments
        },
      }
      this.$store.dispatch('OffersApplications/submitTenancyApplication', payload)
      .then( () => {
        this.loading = false
        this.applicationSuccessful = true
        this.model++
      })
      .catch(err => {
        this.applicationsUnsuccessful = true
        this.model++
        this.loading = false
        this.errorMsg = err
      })
    },
    setTenancy(property) {
      if(this.noTenancy) {
        this.noTenancy = false
      }
      if(property.OID) {
        const proptertyID = property.OID
        this.$store.dispatch('propertyTenanciesLatest', proptertyID) //get full details 
        .then(res => {
          if(res.data.Property === null) { // if no tenancy registered inform user
            this.noTenancy = true // error msg displays
          } else {
            this.selectedTenancy = res.data
          }
        })
      }
    },
    setPerson(person) {
      const personID = person.OID
      this.$store.dispatch('getTenantDetails', personID)
      .then( res => {
        const applicant = {...res.data, OID: personID}
          this.selectedPerson.id = applicant.OID
          this.selectedPerson.title = applicant.Title
          this.selectedPerson.forename = applicant.Forename
          this.selectedPerson.surname = applicant.Surname
          this.selectedPerson.email = applicant.EmailAddress
          this.selectedPerson.mobile = applicant.MobilePhone
      })
    },
    // Document uploads
    uploadDocuments(files) {
      files.forEach( file => {
        this.createBase64String(file)
      })

    },
    createBase64String(fileObject) {
      const reader = new FileReader();

      reader.onload = () => {
        const baser64String = reader.result.split("base64,")[1]
        const documentObject = {
          DocumentName: fileObject.name,
          MimeType: fileObject.type,
          Contents: baser64String,
          Size: fileObject.size
        }
        this.attachedDocuments.push(documentObject)
      };

      reader.readAsDataURL(fileObject)
    },
    removeDocument(index) {
      this.attachedDocuments.splice(index, 1)
    },
    // Form scrolling
    onWheel: debounce(function(e) {
      if(e.deltaY > 0) {
        if(this.model <= 20) {
          this.model++
        }
      } else {
        this.model--
      }
      }, 160, {
        'leading': true,
        'trailing': false
    }),
    swipe(direction) {
      if(direction === "Up") {
        this.model++
      } else {
        this.model--
      }
    },
  },
  filters: {
    filterPrice(val) {
      const numberVal = parseInt(val)
      // return val.toLocaleString('en-UK', {style: 'currency', currency: 'GBP'}) 
      return '£' + numberVal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  },
  watch: {
    selectedLivingStatus(val) {
      const livingStatus = `Current living stauts: ${val}`
      this.generalNotes = livingStatus
    } 
  }
}
</script>

<style>
#buttons-wrapper {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  right: 10px;
  display: flex;
}

#intro-icon {
  opacity: 20%;
}
</style>